
.dashboardgroup{
    display:grid;
    grid-template-areas: 
    "tituloynotificaciones"
    "actions"
    "actividad_academica"
    "pie"
    "grafica"
    "personal_information"
    "last_classes";
    overflow-x: hidden; /* Asegura que la barra de scroll siempre sea visible */
    overflow-x: auto; /* Agregar scroll lateral */
    background-color: #D1D9E2;
    padding: 10px;
    width: 100%;

    & div.dashboardcontainergroup{
        display: grid;
        margin-bottom: 50px;
        grid-template:
        "tituloynotificaciones tituloynotificaciones tituloynotificaciones"10%
        "actions actions actions" auto
        "actividad_academica actividad_academica personal_information" auto
        "grafica pie personal_information" auto
        "last_classes last_classes personal_information" auto/
         35% 35% auto; 
        gap: 10px;
        & div.tituloynotificaciones{
            background-color: white;
            grid-area: tituloynotificaciones;
            border-radius: 10px;
            display: flex;
            float: right;
            justify-content: space-between;
            align-items: center;
            padding: 20px;
        }
        & div.filtrosandbackbtn{
            border-radius: 10px;
            background-color: white;
            grid-area: actions;
            display: flex;
            float: right;
            justify-content: space-between;
            align-items: center;
            padding: 20px;

            & div.actions{
                display: flex;
                float: right;
                justify-content: space-between;
                align-items: center;
                padding: 0 20px 0 20px;
                gap: 10px;
            }
        }
        & div.resumenDeActividadAcademica{
            border-radius: 10px;
            background-color: white;
            grid-area: actividad_academica;
            display: flex;
            justify-content: space-between;
            gap: 10px;
            padding: 10px;
            flex-wrap: wrap;
        }
        & div.actividadCard {
            
            border-radius: 8px;
            padding: 10px;
            
            text-align: center;
            flex: 1;
            min-width: 50px;
            max-width: 150px;
        }
        & div.actividadCard h3 {
            font-size: 1rem;
            margin-bottom: 10px;
            border-bottom: 2px solid #ccc;
            padding-bottom: 5px;
            min-width: 50px;
            height: 60%;
            color: #333;
        }
        & div.actividadCard p.total {
            font-size: 1.5em;
            font-weight: bold;
            color: #007bff;
        }
        & div.pie{
            border-radius: 10px;
            background-color: white;
            grid-area: pie;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        & div.grafica{
            border-radius: 10px;
            background-color: white;
            grid-area: grafica;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        & div.informacionDetalladaEstudiante{
            padding: 50px;
            background-color: white;
            border-radius: 10px;
            grid-area: personal_information;
        }
        & div.ultimasclasesvistas{
            background-color: white;
            border-radius: 10px;
            grid-area: last_classes;
            max-height: 600px;
            overflow-y: auto;
            padding: 30px;  
        }
    }
}


@media screen and (max-width: 600px) {
    .dashboardgroup {
        overflow-x: scroll; /* Asegura que la barra de scroll siempre sea visible */
        overflow-x: auto; /* Agregar scroll lateral */
        & div.dashboardcontainergroup{
            height: auto;
            grid-template:
            "tituloynotificaciones"auto
            "actions" 40px
            "actividad_academica" 15%
            "grafica" auto
            "pie" auto
            "calendario" auto
            "last_classes" auto
            "personal_information" auto/
            100%; 
        }
    }

}