
.dashboardstudiantesadmvista{
    display:grid;
    grid-template-areas: 
    "tituloynotificaciones"
    "actions"
    "actividad_academica"
    "calendario"
    "grafica"
    "personal_information"
    "last_classes";
    overflow-x: auto; /* Asegura que la barra de scroll siempre sea visible */
 /* Asegúrate de que el contenedor tenga un ancho máximo definido */
    background-color: #D1D9E2;
    padding: 10px;
    width: 100%;
    & div.dashboardcontainerstudentviewadmin{
        display: grid;
        width: 100%;
        margin-bottom: 50px;
        grid-template:
        "tituloynotificaciones tituloynotificaciones tituloynotificaciones"10% 
        "actions actions actions" auto
        "personal_information actividad_academica actividad_academica" auto
        "personal_information grafica calendario" auto
        "personal_information last_classes last_classes" auto/
         auto auto auto; 
        gap: 10px;
        & div.tituloynotificaciones{
            background-color: white;
            grid-area: tituloynotificaciones;
            border-radius: 10px;
            display: flex;
            float: right;
            justify-content: space-between;
            align-items: center;
            padding: 20px;
        }
        & div.filtrosandbackbtn{
            border-radius: 10px;
            background-color: white;
            grid-area: actions;
            display: flex;
            float: right;
            justify-content: space-between;
            align-items: center;
            padding: 20px;

            & div.actions{
                display: flex;
                float: right;
                justify-content: space-between;
                align-items: center;
                padding: 0 20px 0 20px;
                gap: 10px;
            }
        }
        & div.resumenDeActividadAcademica{
            border-radius: 10px;
            background-color: white;
            grid-area: actividad_academica;
            display: flex;
            justify-content: space-between;
            gap: 10px;
            padding: 5px;
            flex-wrap: wrap;
        }
        & div.actividadCard {
            
            border-radius: 8px;
            padding: 10px;
            
            text-align: center;
            flex: 1;
            min-width: 50px;
            max-width: 150px;
        }
        & div.actividadCard h3 {
            font-size: 1rem;
            margin-bottom: 10px;
            border-bottom: 2px solid #ccc;
            padding-bottom: 5px;
            min-width: 50px;
            height: 60%;
            color: #333;
        }
        & div.actividadCard p.total {
            font-size: 1.5em;
            font-weight: bold;
            color: #007bff;
        }
        & div.calendario{
            border-radius: 10px;
            background-color: white;
            grid-area: calendario;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        & div.grafica{
            border-radius: 10px;
            background-color: white;
            grid-area: grafica;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        & div.informacionDetalladaEstudiante{
            padding: 20px;
            background-color: white;
            border-radius: 10px;
            grid-area: personal_information;
        }
        & div.ultimasclasesvistas{
            padding: 20px;
            background-color: white;
            border-radius: 10px;
            grid-area: last_classes;
            min-height: 250px;
            max-height: 400px;
            overflow-x: auto;
        }
    }
}


@media screen and (max-width: 1298px) {
    .dashboardstudiantesadmvista{
        & div.dashboardcontainerstudentviewadmin{
            display: grid;
            width: 100%;
            grid-template:
            "tituloynotificaciones tituloynotificaciones"10% 
            "actions actions" auto
            "actividad_academica actividad_academica" auto
            "grafica calendario" auto
            "last_classes last_classes" auto
            "personal_information personal_information" auto/
             auto auto;
        }
    }

}