.dashboardprofesorview{
    display:grid;
    grid-template-areas: 
    "tituloynotificaciones"
    "actions"
    "actividad_academica"
    "totales"
    "ultimas_clases_estudiante";

    overflow-x: hidden; /* Asegura que la barra de scroll siempre sea visible */
    overflow-x: auto; /* Agregar scroll lateral */
    background-color: #D1D9E2;
    padding: 10px;
    width: 100%;

    & div.dashboardcontainergroup{
        display: grid;
        margin-bottom: 50px;
        grid-template:
        "tituloynotificaciones tituloynotificaciones tituloynotificaciones"10%
        "actions actions actions" auto
        "actividad_academica actividad_academica totales" auto
        "ultimas_clases_estudiante ultimas_clases_estudiante totales" auto
        "ultimas_clases_estudiante ultimas_clases_estudiante totales"/
         35% 35% auto; 
        gap: 10px;
        & div.tituloynotificaciones{
            background-color: white;
            grid-area: tituloynotificaciones;
            border-radius: 10px;
            display: flex;
            float: right;
            justify-content: space-between;
            align-items: center;
            padding: 20px;
        }
        & div.filtrosandbackbtn{
            border-radius: 10px;
            background-color: white;
            grid-area: actions;
            display: flex;
            float: right;
            justify-content: space-between;
            align-items: center;
            padding: 20px;

            & div.actions{
                display: flex;
                float: right;
                justify-content: space-between;
                align-items: center;
                padding: 0 20px 0 20px;
                gap: 10px;
            }
        }
        & div.resumenDeActividadAcademica{
            border-radius: 10px;
            background-color: white;
            grid-area: actividad_academica;
            display: flex;
            justify-content: space-between;
            gap: 10px;
            padding: 10px;
            flex-wrap: wrap;
        }
        & div.actividadCard {
            border-radius: 8px;
            padding: 10px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            text-align: center;
            flex: 1;
            min-width: 50px;
            max-width: 150px;
        }
        & div.actividadCard h3 {
            font-size: 1rem;
            margin-bottom: 10px;
            border-bottom: 2px solid #ccc;
            padding-bottom: 5px;
            min-width: 50px;
            height: 60%;
            color: #333;
        }
        & div.actividadCard p.total {
            font-size: 1.5em;
            font-weight: bold;
            color: #007bff;
        }
        & div.totales{
            padding: 50px;
            background-color: white;
            border-radius: 10px;
            grid-area: totales;
        }
        & div.ultimas_clases_estudiante{
            padding: 20px;
            background-color: white;
            border-radius: 10px;
            grid-area: ultimas_clases_estudiante;
            overflow-x: auto;
            max-height: 100%;
            overflow-y: auto;
        }
    }
}


@media screen and (max-width: 1144px) {
    .dashboardprofesorview div.dashboardcontainergroup {
        grid-template:
        "tituloynotificaciones  "10%
        "actions" auto
        "actividad_academica" auto
        "ultimas_clases_grupo" auto
        "ultimas_clases_estudiante"
        "totales"/
         auto; 
    }
}

@media screen and (max-width: 600px) {
    .dashboardprofesorview div.dashboardcontainergroup {
         & div.resumenDeActividadAcademica {
            flex-direction: column;
            & div.actividadCard {
                max-width: 100%;
                display: flex;
                justify-content: space-between;
                & h3{
                    border-bottom: 0px; 
                }
            }
         }
         & div.totales{
            padding: 10px;
        }
        & div.filtrosandbackbtn {
            flex-direction: column;
            align-items: flex-start;
            & div.actions{
                flex-direction: column;
                padding:0;
                align-items: flex-start;
            }
        }
    }
}